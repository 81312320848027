//
//
//
//

import Error from '~/layouts/error.vue';

export default {
  components: {
    Error,
  },
};
